import { useState } from "react";
import MetaAwakening from "../MetaAwekingAnimation";
import MetaAwekingImg from "../../asstes/images/backgroundimages/Home_animation_img1.jpeg";
import bhupendraBanner from "../../asstes/images/bhupendra-banner.jpg";
import BhupendraQauntumForce from "../../asstes/images/backgroundimages/Home_animation_img3.jpg";
import "../../asstes/styles/home.scss";

// -------------------------------------------------------

const MetaAwakeningContainer = () => {
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);

  return (
    <div className="Awakening-container exce-home-page-animation-aweking">
      <div className="Awakening-section">
        <MetaAwakening
          id={'first-MetaAwaking'}
          desc={'Through Meta Awakening, you uncover your true needs, align with your soul’s purpose, and gain clarity on where you’re meant to go and what you’re meant to become—empowering you to design a life of authentic success, lasting happiness, and deeply fulfilling relationships, all in harmony with the higher reason for which you were born.'}
          title={'The Meta Awakening'}
          img={MetaAwekingImg}
          showing={1}
          setProgress={setProgress1}
        />
      </div>
      <div className="Awakening-section">
        <MetaAwakening
          id={'first-MetaAwaking'}
          desc={'Chaitanya Yog Kriya is a 3-day immersive journey that leads you to a profound realization of your real self by bringing the body, brain, and being into deep alignment. Rooted in timeless wisdom yet tailored for the modern seeker, this experience gently dissolves inner fragmentation and reveals a state of expanded consciousness, where clarity, connection, and celebration naturally arise. Through precise kriya practices, you regenerate from within and access the quantum field of Ultimate Bliss & Celebration, enabling a life of genuine peace, purpose, and fulfillment.'}
          title={'Chaitanya Yog Kriya'}
          img={bhupendraBanner}
          setProgress={setProgress2}
        />
      </div>
      <div className="Awakening-section">
        <MetaAwakening
          id={'first-MetaAwaking'}
          desc={'Bhupendraz QuantumForce is a powerful system that aligns your awareness, energy, and intelligence to enhance leadership and transform workplace dynamics.Rooted in timeless wisdom and built for modern challenges, it sharpens clarity, improves communication, and strengthens emotional resilience.Through practical tools and guided practices, you activate your inner force and lead with focus, confidence, and purpose.'}
          title={'Bhupendraz Quantum Force'}
          img={BhupendraQauntumForce}
          setProgress={setProgress3}
        />
      </div>
      <div className="event-progress-container" >
        <div className="event-progress-sticky" >
          <div className="event-progress-div" >
            <span style={{ width: `${progress1}%` }} className="event-progress-bar" ></span>
          </div>
          <div className="event-progress-div" >
            <span style={{ width: `${progress1 < 100 ? 0 : progress2}%` }} className="event-progress-bar" ></span>
          </div>
          <div className="event-progress-div" >
            <span style={{ width: `${progress2 < 100 ? 0 : progress3}%` }} className="event-progress-bar" ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaAwakeningContainer;